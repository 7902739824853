import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2b2b2b',
        },
        secondary: {
            main: '#f8bc2e',
        }
    },
});

export default theme